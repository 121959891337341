$default-text-dark: #292929
$default-text: #292929

html.is_dark, html.is-dark
  --color-background: #222
  --color-text-strong: #E6E6E6
  --color-text: #aaa
  --color-text-quota: #777
  --color-border: #333
  --color-primary: hsl(210, 60%, 60%)
  --color-background-faded: #333
  --color-background-sidebar: #1a1a1a
  --color-background-code: #333
  --box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.8)

\:root
  --color-background: white
  --color-text: #292929
  --color-text-quota: dimgray
  --color-border: #eee
  --color-primary: hsl(210, 70%, 50%)
  --color-background-faded: #f2f2f2
  --color-background-sidebar: #fafafa
  --color-background-code: #f2f2f2
  --box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1)
  --font-size-small: 85%


html.is_dark img
  opacity: var(--opacity)
  transition: opacity var(--transition-duration)
  &:hover
    opacity: 1

#main
  max-width: 860px
  margin: 0 auto
  padding: 18px
  padding-bottom: 100px
  @media only screen and (max-width: 920px)
    font-size: 16px
  &:lang(en)
    font-size: 18px
    word-wrap: break-word
    word-break: break-word
    hyphens: none
  & >
    ul:first-child, ol:first-child
      margin-top: 30px

@media only screen and (min-width: 1400px)
  #main
    max-width: 1024px
  
@media only screen and (min-width: 1800px)
  #main
    max-width: 1200px

a
  color: var(--color-primary)

h1, h2, h3, h4, h5, h6
  position: relative
  margin-top: 1rem
  margin-bottom: 1.75rem
  font-weight: bold
  line-height: 1.25
  cursor: text
  -webkit-font-smoothing: antialiased
  &:lang(en)
    font-weight: 500
    margin-bottom: 2.25rem

h1, h2, h3, h4
  margin-top: 1.75rem

h5
  margin-top: 1.5rem


h1:hover a.anchor, h2:hover a.anchor, h3:hover a.anchor, h4:hover a.anchor, h5:hover a.anchor, h6:hover a.anchor
  text-decoration: none

@for $i from 1 through 6
  h#{$i}
    tt, code
        font-size: inherit

p, blockquote, ul, ol, dl, table
  margin: 1.25rem 0 0 0
  line-height: 1.7rem
  &:lang(en)
    text-align: left !important

li >
  ol, ul
    margin: 0 0

hr
  height: 2px
  padding: 0
  margin: 16px 0
  background-color: var(--color-border)
  border: 0 none
  overflow: hidden
  box-sizing: content-box

html
  color: var(--color-text)
  background-color: var(--color-background)

code, kbd, samp, tt
  background-color: var(--color-background-code)
  font-size: var(--font-size-small)
  padding: 0.2em 0.3em

pre
  background-color: var(--color-background-faded)
  line-height: 1.2
  overflow: auto
  padding: 0.85rem 1rem
  code
    background-color: transparent
    padding: 0
    
code[class*='language-']
  font-size: var(--font-size-small) !important

//Dark mode override
// strong
//   color: var(--color-text-strong)

blockquote
  box-shadow: inset 3px 0 0 0 var(--color-text)
  margin-left: -20px
  padding-left: 23px
  /* font-style: italic; */
  @media only screen and (max-width: 920px)
    margin-left: -12px  !important
    padding-left: 15px
  strong
    color: var(--color-text)
  &:lang(en)
    font-style: italic
  &:not(:lang(en))
    color: var(--color-text-quota)